<template>
  <FullScreenDialog
    v-model="operatorDialog"
    title="Operatori"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in operatorTabs"
          :key="tab.key"
        >{{tab.title}}<span v-if="tab.suffix" class="dot"></span></v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item :eager="true" v-for="tabItem in operatorTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component" :is-new="true"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        :disabled="loading"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        :loading="loading"
        @click="submitForm"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import RegistryForm from "@/components/areas/registries/operators/form/RegistryFormTab.vue"
import EnablingForm from "@/components/areas/registries/operators/form/EnablingFormTab.vue"
import operatorService from "@/services/operators/operators.service.js"
import operatorForm from '@/services/operators/operator.form.js'

export default {
  name: "OperatorsRegistryNewForm",
  components: {
    FullScreenDialog,
    RegistryForm,
    EnablingForm
  },
  data: function() {
    return {
      tab: null,
      operatorDialog: this.openDialog,
      operatorTabs: [
        {
          key: 0,
          title: "Anagrafica",
          icon: "mdi-book",
          name: "registry",
          suffix: false,
          component: "RegistryForm"
        },
        {
          key: 1,
          title: "Abilitazioni",
          name: "enablings",
          icon: "mdi-book-plus-outline",
          suffix: false,
          component: "EnablingForm"
        }
      ],
      formValid: false,
      operator: undefined,
      loading: false,
      originalUrl: window.location.pathname
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    operatorForm.resetOperator()
    operatorForm.on('update', function(data) {
      if (!data.operator.enablingServices || data.operator.enablingServices.length === 0)
        self.operatorTabs[1].suffix = true
      else 
        self.operatorTabs[1].suffix = false
      
      self.operator = data.operator
    })

    operatorForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/registries"
    },
    tabName: {
      type: String,
      default: 'registry'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.operatorTabs.length; i++) {
        if(this.operatorTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    stringToHTML(str) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(str, 'text/html')
      return doc.body
    },
    submitForm() {
      this.loading = true
      if (!this.operator.enablingServices || this.operator.enablingServices.length === 0) {
        this.$delegates.snackbar("Attenzione! Scegliere almeno un'abilitazione")
        this.loading = false
      }
      else {
        operatorService.create(this.operator).then((result) => {
          this.$router.push({path: "/registries/operators/list" }).then(() => {
            this.loading = false
          })
        }).catch((error) => {
          if(error.message == "Code Already Present") {
            this.$delegates.snackbar("Barcode già presente")
            this.loading = false
          }
          else
            this.$router.push({path: "/registries/operators/list" }).then(() => {
              this.loading = false
            })
        })
      }
    }
  },
  watch: {
    openDialog(newVal) {
      this.operatorDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.operatorTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    },
  },
  computed: {
  }
};
</script>

<style>
 .dot {
      height: 0.8em;
      width: 0.8em;
      background-color: rgb(219, 2, 2);
      border-radius: 50%;
      float: right;
      margin-bottom: 2em;
      margin-right: 2em;
    }
</style>